import { render } from "@testing-library/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./features/users/auth/provider";
import { Auth } from "./features/qc/types";
import { ConfigProps, ConfigProvider } from "./config";
import {
  TableProvider,
  TableProviderValue,
} from "./features/document-tables/providers/table-provider";
import { times } from "lodash";
import { SourceFlagSet } from "@hooks/useFlags";
import { DrawerContextProvider } from "@app/esource/shared-components/DrawerContainer";

export type ConfigPropsWithFlags = ConfigProps & {
  flags: Partial<SourceFlagSet>;
};

export const defaultConfigValues: ConfigPropsWithFlags = {
  doaApiRoot: "http://localhost/",
  esourceApiRoot:
    process.env.VITE_ESOURCE_API_ROOT || "http://localhost/documents/api",
  apiGatewayRoot:
    process.env.VITE_API_GATEWAY_ROOT ||
    "http://localhost/api/api-gateway/graphql",
  auth0Realm: () => "",
  auth0ServiceConfig: () => ({
    domain: process.env.VITE_AUTH0_DOMAIN,
    clientID: process.env.VITE_AUTH0_CLIENT_ID,
    responseType: "id_token",
    scope: "openid profile email",
  }),
  flags: {},
};

const defaultAuthValues: Auth = {
  logout: () => Promise.resolve(),
  rawToken: "xyz123",
  setRawToken: () => null,
  allowedActions: [],
  isLoading: false,
  user: {
    key: "testkey",
    name: "testusername",
    email: "myName@gmail.com",
    role: "testrole",
    view: false,
    upload: false,
    admin: false,
    description: "testdescription",
    studyteamUserId: "testId",
  },
  isAuthenticated: true,
};

export type CustomRenderOptions = {
  flags?: Partial<SourceFlagSet>;
  routeOptions?: { path: string; route: string };
  auth?: Partial<Auth>;
  tableProviderValues?: Partial<TableProviderValue>;
};

const mockedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
    },
  },
});

export const getBaseComponent = (options?: CustomRenderOptions) => {
  return ({ children }: { children: React.ReactNode }) => {
    const allowedActionOptions = options?.auth?.allowedActions || [];
    const routeOption = options?.routeOptions?.route || "/";
    const pathOption = options?.routeOptions?.path || "/";

    return (
      <Router initialEntries={[routeOption]}>
        <QueryClientProvider client={mockedQueryClient}>
          <ConfigProvider.Provider
            value={{ ...defaultConfigValues, flags: options?.flags || {} }}
          >
            <AuthProvider.Provider
              value={{
                ...defaultAuthValues,
                allowedActions: [
                  ...defaultAuthValues.allowedActions,
                  ...allowedActionOptions,
                ],
              }}
            >
              <TableProvider testOptions={options?.tableProviderValues}>
                <DrawerContextProvider>
                  <Routes>
                    <Route path={pathOption} element={children}></Route>
                  </Routes>
                </DrawerContextProvider>
              </TableProvider>
            </AuthProvider.Provider>
          </ConfigProvider.Provider>
        </QueryClientProvider>
      </Router>
    );
  };
};

const provideForStoryBookTemplate = (
  template: any,
  options: CustomRenderOptions = {},
) => {
  const Wrapper = getBaseComponent(options);
  return <Wrapper>{template}</Wrapper>;
};

const customRender = (ui: any, options?: CustomRenderOptions) => {
  const Wrapper = getBaseComponent(options);
  return render(ui, { wrapper: Wrapper, ...options });
};

const randomAlphaChar = () => {
  const a = "a".charCodeAt(0);
  const z = "z".charCodeAt(0);
  return String.fromCharCode(Math.floor(a + Math.random() * (z - a + 1)));
};

// Generates a random string of lowercase alphabetic
// characters with size `length`
export const randomAlphaString = (length: number) =>
  times(length, randomAlphaChar).join("");

// re-export everything
export * from "@testing-library/react";
// override render method
export { customRender as render, provideForStoryBookTemplate as provide };
